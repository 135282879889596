<template>



  <b-row class="match-height">
    <b-col md="12">
    <ag-grid-table 
        configUrl="http://camanchaca.localhost:62907/api/lvgt/config" 
        dataUrl="http://camanchaca.localhost:62907/api/lvgt/data">
    </ag-grid-table>
    </b-col>
    <b-col md="12">
        <highcharts-chart 
        configUrl="http://camanchaca.localhost:62907/api/chart/config"
        dataUrl="http://camanchaca.localhost:62907/api/chart/data"  />
    </b-col>    
    <b-col md="12">
        <highcharts-chart 
        configUrl="http://camanchaca.localhost:62907/api/highchart/config"
        dataUrl="http://camanchaca.localhost:62907/api/highchart/data"  />
    </b-col>    



    </b-row>


    
</template>

<script>

import { 
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VBPopover,  
 } from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import HighchartsChart from '@/views/brunacomponents/HighchartsChart.vue'



export default {
    components: {
        BRow,
        BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VBPopover, 
    AgGridTable,
    HighchartsChart

 
    }, 

    directives: {
        "b-popover": VBPopover,
    },

    data: function() {
        return {
           
        };
    },
    created() {


    },

    mounted() {


    var channel = this.$pusher.subscribe('charts');
    channel.bind('charts', function(data) {
        console.log(data);

    });        
        


    },

    methods: {

      
    }
};
</script>

<style lang="scss">


</style>

