<template>
    <card-tooltip
        :title="cardTitle"
        :subTitle="cardSubTitle"
        :tooltipTitle="cardTooltipTitle"
        :tooltipContent="cardTooltipContent"
        :showLoading="isLoading"
    >
        <template v-slot:card-body>
            <Highcharts v-if="chartComponent == 'highcharts'" :options="options" />
            <Highstock v-if="chartComponent == 'highstocks'" :options="options" />
        </template>
    </card-tooltip>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BSpinner,
    VBPopover,
    BCardText,
} from "bootstrap-vue";

import CardTooltip from "@/views/brunacomponents/wrappers/CardTooltipWrapper.vue";

//import Highcharts from "highcharts/highcharts.src.js";
import Highcharts from "highcharts";
import loadStock from "highcharts/modules/stock.js";

import loadIndicatorsAll from "highcharts/indicators/indicators-all.js";
import loadDragPanes from "highcharts/modules/drag-panes.js";
import loadAnnotationsAdvanced from "highcharts/modules/annotations-advanced.js";
import loadPriceIndicator from "highcharts/modules/price-indicator.js";
import loadFullScreen from "highcharts/modules/full-screen.js";
import loadStockTools from "highcharts/modules/stock-tools.js";

import loadData from "highcharts/modules/data.js";


import loadParallelCoordinates from "highcharts/modules/parallel-coordinates.js";


//import loadStyle from "highcharts/themes/brand-light.js"

import { genComponent } from "vue-highcharts";

const axios = require("axios");

//loadStyle(Highcharts);
loadStock(Highcharts);
loadData(Highcharts);
loadParallelCoordinates(Highcharts)

loadIndicatorsAll(Highcharts);
loadDragPanes(Highcharts); 
loadAnnotationsAdvanced(Highcharts);
loadPriceIndicator(Highcharts);
loadFullScreen(Highcharts);
loadStockTools(Highcharts);

export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCardSubTitle,
        BCardTitle,
        BCardText,
        BSpinner,

        CardTooltip,

        Highcharts: genComponent("Highcharts", Highcharts),
        Highstock: genComponent("Highstock", Highcharts),
    },

    directives: {
        "b-popover": VBPopover,
    },

    created() {
        //console.log("created 2");

        this.isLoading = true;

        this.getChartConfig().then((response) => {
            const { chartConfig, cardConfig } = response.data;

            const {
                endDate,
                enablePolling,
                dataRefreshRate,
                component,
                updateStrategy,
                options,
            } = chartConfig;

            const { title, subtitle, tooltipTitle, tooltipContent } = cardConfig;

            this.cardTitle = title;
            this.cardSubTitle = subtitle;

            this.cardTooltipTitle = tooltipTitle;
            this.cardTooltipContent = tooltipContent;

            this.endDate = endDate;
            this.enablePolling = enablePolling;
            this.dataRefreshRate = dataRefreshRate;
            this.chartComponent = component;
            this.updateStrategy = updateStrategy;

            this.options = Object.assign(this.defaultOptions, options);

            //console.log("getChartConfig end 2");

            this.isLoading = false;
        });
    },

    methods: {
        getChartConfig() {
            return axios.request({
                method: "get",
                url: this.configUrl,
                params: {
                    endDate: this.endDate,
                },
                headers: {
                    Accept: "application/json",
                },
            });
        },

        getChartData() {
            return axios.request({
                method: "get",
                url: this.dataUrl,
                params: {
                    endDate: this.endDate,
                },
                headers: {
                    Accept: "application/json",
                },
            });
        },

        chartLoad(event) {
            //console.log("chartLoad 2");
            let chart = event.target;

            this.chart = chart;

            if (this.enablePolling) {
                this.pollData();
            }
        },

        chartAfterSetExtremes(event) { },

        updateSeries(updatedSeries) {
            updatedSeries.forEach((updatedSerie, index) => {
                //console.log(updatedSerie.id)

                let series = this.chart.get(updatedSerie.id);

                //console.log({updatedSerie, series})
                //console.log(this.updateStrategy)

                if (this.updateStrategy == "replace") {
                    series.setData(updatedSerie.data, true, true);
                }
                if (this.updateStrategy == "append") {
                    updatedSerie.data.forEach((point) => {
                        //console.log({point})
                        series.addPoint(point, true, true);
                    });
                }
            });
        },

        stopPolling() {
            if (this.polling) {
                clearInterval(this.polling);
            }
        },

        pollData() {
            //console.log("polldata 2");
            if (!this.enablePolling || !this.dataRefreshRate) {
                return;
            }

            //console.log("creo el interval 2");
            this.polling = setInterval(() => {
                this.getChartData().then((response) => {
                    const {
                        endDate,
                        enablePolling,
                        dataRefreshRate,
                        updateStrategy,
                        series,
                    } = response.data;

                    this.endDate = endDate;
                    this.enablePolling = enablePolling;
                    this.dataRefreshRate = dataRefreshRate;
                    this.updateStrategy = updateStrategy;

                    this.updateSeries(series);

                    if (!this.enablePolling) {
                        this.stopPolling();
                    }
                });
            }, this.dataRefreshRate);
        },
    },

    mounted() {
        //console.log("mounted 2");
    },

    beforeDestroy() {
        //console.log("beforeDestroy 2");

        this.stopPolling();
    },

    data() {
        return {
            cardTitle: "",
            cardSubTitle: "",

            cardTooltipTitle: "",
            cardTooltipContent: "",

            chartComponent: "highcharts",

            isLoading: true,

            chart: null,

            enablePolling: false,
            dataRefreshRate: null,
            updateStrategy: "replace",
            polling: null,

            endDate: null,

            defaultOptions: {
                stockTools: {
                    gui: {
                        enabled: false,
                    },
                },

                chart: {
                    events: {
                        load: (e) => {
                            this.chartLoad(e);
                        },
                    },
                },

                xAxis: {
                    events: {
                        afterSetExtremes: (e) => {
                            this.chartAfterSetExtremes(e);
                        },
                    },
                },
            },

            options: {},
        };
    },

    props: {
        configUrl: {
            type: String,
            required: true,
        },
        dataUrl: {
            type: String,
            required: true,
        },
    }, 
};
</script>


<style lang="scss">

@import "~highcharts/css/highcharts.css";

@import "~highcharts/css/stocktools/gui.css";
@import "~highcharts/css/annotations/popup.css";
</style>
